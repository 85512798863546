<template>
  <div class="balance-fix-sidebar balance-fix-sidebar-custom">
    <b-row no-gutters class="mt-2">
      <h3>{{$t(translationPath + 'heading')}}</h3>
      <div v-html="$t(translationPath + 'introduction')"></div>
      <b-col>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="amount-date"
          required
          :invalid-feedback="invalidDateFeedback"
          :label="$t(translationPath + 'date')"
        >
          <datepicker
            id="date"
            v-model="date"
            :input-class="dateFieldClasses"
            :typeable="true"
            :required="true"
            :format="'yyyy-MM-dd'"
            :placeholder="'YYYY-MM-DD'"
            :language="selectedLang"
            aria-describedby="input-2-live-feedback"
            :monday-first="startWeekByMonday"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="title"
          required
          :invalid-feedback="invalidTitleFeedback"
          :label="$t(translationPath + 'title')"
        >
          <b-form-input
            v-model="title"
            id="title"
            class="mb-2"
            :state="$v.title.$dirty ? !$v.title.$error : null"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <label>
          {{ $t(translationPath + 'debit_account') }}
          (<b-link :title="$t(translationPath + 'set_to_selected_account_title', {account: this.accountTitle})" @click="selectFromMainAccount('dr')">{{$t(translationPath + 'set_to_selected_account')}}</b-link>,
          <b-link :title="$t(translationPath + 'swap_accounts_title')" @click="swapAccounts()">{{$t(translationPath + 'swap_accounts')}}</b-link>)
        </label>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="debit-account"
          required
          :invalid-feedback="invalidDebitAccountFeedback"
          :state="!$v.debitAccount.$dirty && !$v.debitAccount.$error"
          :description="$t(translationPath + 'debit_account_description')"
        >
          <custom-select
            :key="renderKey"
            id="debit-account"
            class="mb-2"
            :extra-classes="debitAccountFieldClasses"
            :options="accountOptions"
            grouping-values="accounts"
            grouping-label="label"
            :label="'label'"
            :loading="!isLoaded"
            v-model="debitAccount"
            :placeholder="$t('common.select_account')"
            :search-placeholder="$t('common.type_to_filter')"
            :noResultText="$t(translationPath + 'no_more_items')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <label>
          {{ $t(translationPath + 'credit_account') }}
          (<b-link :title="$t(translationPath + 'set_to_selected_account_title', {account: this.accountTitle})" @click="selectFromMainAccount('cr')">{{$t(translationPath + 'set_to_selected_account')}}</b-link>,
          <b-link :title="$t(translationPath + 'swap_accounts_title')" @click="swapAccounts()">{{$t(translationPath + 'swap_accounts')}}</b-link>)
        </label>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="credit-account"
          required
          :invalid-feedback="invalidCreditAccountFeedback"
          :state="!$v.creditAccount.$dirty && !$v.creditAccount.$error"
          :description="$t(translationPath + 'credit_account_description')"
        >
          <custom-select
            :key="renderKey"
            id="credit-account"
            class="mb-2"
            :extra-classes="creditAccountFieldClasses"
            :options="accountOptions"
            grouping-values="accounts"
            grouping-label="label"
            :label="'label'"
            :loading="!isLoaded"
            v-model="creditAccount"
            :placeholder="$t('common.select_account')"
            :search-placeholder="$t('common.type_to_filter')"
            :noResultText="$t(translationPath + 'no_more_items')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="amount"
          required
          :invalid-feedback="invalidAmountFeedback"
          :label="$t(translationPath + 'amount')"
        >
          <currency-input
            id="amount"
            v-model="amount"
            class="mb-2"
            :class="amountFieldClasses"
            :min="0"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters class="pt-3">
      <b-col>
        <b-button variant="primary" class="mr-2 mb-2" :disabled="!isLoaded || disableButtons" @click.prevent="onClickPreview"> {{ $t(translationPath + 'btn_preview') }}</b-button>
        <b-button v-if="isPreviewLoaded" variant="outline-secondary" class="mr-2 mb-2" :disabled="!isLoaded || disableButtons" @click.prevent="onClickClearPreview"> {{ $t(translationPath + 'btn_clear_preview') }}</b-button>
        <b-button variant="primary" class="mr-2 mb-2" :disabled="!isLoaded || disableButtons" @click.prevent="onClickCreateTransaction"> {{ $t(translationPath + 'btn_create_transaction') }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import CustomSelect from '@/components/common/CustomSelect'
import CurrencyInput from '@/components/common/CurrencyInput'

const isDateFormatCorrect = getter => function (val) {
  return moment(val).isValid()
}
const isNotZero = getter => function (val) {
  return Boolean(val)
}
const isAccountsFitParam = getter => function () {
  if (!this.accountId || !this.debitAccount || !this.creditAccount) {
    return false
  }
  if (this.debitAccount.id === this.accountId || this.creditAccount.id === this.accountId) {
    return true
  }
  return false
}

export default {
  name: 'BalanceFixSidebarCustom',
  components: { Datepicker, CustomSelect, CurrencyInput },
  props: {
    selectedLang: {
      type: Object
    },
    startWeekByMonday: {
      type: Boolean,
      default: true
    },
    accountOptions: {
      type: Array,
      default: null
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: 'SEK'
    },
    locale: {
      type: String,
      default: 'en-US'
    },
    isPreviewLoaded: {
      type: Boolean,
      default: false
    },
    disableButtons: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: Number,
      default: 0
    },
    accountTitle: {
      type: String,
      default: '-'
    }
  },
  data () {
    return {
      translationPath: 'transactions.balance-fix.sidebar.custom.',
      date: new Date(),
      title: '',
      debitAccount: null,
      creditAccount: null,
      amount: 0,
      renderKey: 0
    }
  },
  validations: {
    date: { required, isDateFormatCorrect: isDateFormatCorrect() },
    title: { required, minLength: minLength(2), maxLength: maxLength(191) },
    debitAccount: { required, isAccountsFitParam: isAccountsFitParam() },
    creditAccount: { required, isAccountsFitParam: isAccountsFitParam() },
    amount: { required, isNotZero: isNotZero() },
    form: ['date', 'title', 'debitAccount', 'creditAccount', 'amount']
  },
  computed: {
    dateFieldClasses () {
      let str = 'form-control input-date mb-2 '
      if (this.$v.date.$dirty && !this.$v.date.$error) {
        str += ' is-valid'
      } else if (this.$v.date.$dirty && this.$v.date.$error) {
        str += 'is-invalid'
      }
      return str
    },
    debitAccountFieldClasses () {
      return {
        'is-valid': this.$v.debitAccount.$dirty && !this.$v.debitAccount.$error,
        'is-invalid': this.$v.debitAccount.$dirty && this.$v.debitAccount.$error
      }
    },
    creditAccountFieldClasses () {
      return {
        'is-valid': this.$v.creditAccount.$dirty && !this.$v.creditAccount.$error,
        'is-invalid': this.$v.creditAccount.$dirty && this.$v.creditAccount.$error
      }
    },
    amountFieldClasses () {
      return {
        'is-valid': this.$v.amount.$dirty && !this.$v.amount.$error,
        'is-invalid': this.$v.amount.$dirty && this.$v.amount.$error
      }
    },
    invalidDateFeedback () {
      if (this.$v.date.required === false) { return this.$t(this.translationPath + 'errors.date.required') }
      if (this.$v.date.isDateFormatCorrect === false) { return this.$t(this.translationPath + 'errors.date.invalid_format') }
      return ''
    },
    invalidTitleFeedback () {
      if (this.$v.title.required === false) { return this.$t(this.translationPath + 'errors.title.required') }
      if (this.$v.title.minLength === false) { return this.$t(this.translationPath + 'errors.title.min_length') }
      if (this.$v.title.maxLength === false) { return this.$t(this.translationPath + 'errors.title.max_length') }
      return ''
    },
    invalidDebitAccountFeedback () {
      if (this.$v.debitAccount.required === false) { return this.$t(this.translationPath + 'errors.debit_account.required') }
      return ''
    },
    invalidCreditAccountFeedback () {
      if (this.$v.creditAccount.required === false) { return this.$t(this.translationPath + 'errors.credit_account.required') }
      if (this.$v.creditAccount.isAccountsFitParam === false) { return this.$t(this.translationPath + 'errors.credit_account.not_match_page_account', { account: this.accountTitle }) }
      return ''
    },
    invalidAmountFeedback () {
      if (this.$v.amount.required === false) { return this.$t(this.translationPath + 'errors.amount.required') }
      if (this.$v.amount.isNotZero === false) { return this.$t(this.translationPath + 'errors.amount.zero') }
      return ''
    }
  },
  created () {
    // this.title = this.$t(this.translationPath + 'new_transaction_title_default')
  },
  methods: {
    onClickPreview () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      this.$emit('preview', this.getFormData())
    },
    onClickClearPreview () {
      this.$emit('clear-preview')
    },
    onClickCreateTransaction () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      this.$emit('create-transaction', this.getFormData())
    },
    selectFromMainAccount (dcType) {
      for (const g in this.accountOptions) {
        for (const a in this.accountOptions[g].accounts) {
          if (this.accountOptions[g].accounts[a].id === this.accountId) {
            if (dcType === 'cr') {
              this.creditAccount = this.accountOptions[g].accounts[a]
            } else {
              this.debitAccount = this.accountOptions[g].accounts[a]
            }
          }
        }
      }
    },
    onSelectMainAccount (account) {
      if (account === null) {
        return false
      }

      if (['asset', 'liability'].indexOf(account.type) === -1) {
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.invalid_main_account_type').toString(), {
          title: this.$t(this.translationPath + 'toast.title').toString(),
          variant: 'danger',
          solid: true,
          autoHideDelay: 6000
        })
        return false
      }

      if (account.id.toString() !== this.$route.params.account_id) {
        this.$emit('select-main-account', account.id)
      }
    },
    swapAccounts () {
      const accountA = this.creditAccount
      this.creditAccount = this.debitAccount
      this.debitAccount = accountA
    },
    getFormData () {
      return {
        date: this.date,
        title: this.title,
        creditAccount: this.creditAccount,
        debitAccount: this.debitAccount,
        amount: this.amount,
        type: 'custom_transaction'
      }
    }
  },
  watch: {
    isLoaded () {
      this.renderKey++
    }
  }
}
</script>
