<!-- http://localhost:8080/transactions/balance-fix/161?date=2022-12-20 -->
<template>
  <div class="component-transactions-balance-fix">

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper page-wrapper bg-light">
      <!-- HEADER -->
      <b-row class="breadcrumb-wrapper" no-gutters>
        <b-col no-gutters cols="12">
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <b-col class="pull-left pl-0 pr-0 pr-md-2">
            <h1 class="text-left">{{ $t(translationPath + 'heading')}}</h1>
          </b-col>
        </b-col>
      </b-row>

      <!-- BODY -->
      <b-container class="main-content-wrapper pt-0">
        <div class="bordered-table mt-3">

          <b-row no-gutters class="mb-3">
            <b-col cols="12" md="6" lg="7" xl="8">
              <h2 class="mt-2">{{ $t(translationPath + 'subheading', {account_title: this.account ? this.account.title : $t(this.translationPath + 'select_account')}) }}</h2>
            </b-col>
            <b-col cols="12" md="6" lg="5" xl="4">
              <period-selector
                show-last-years
                show-last-months
                show-period-option-full-period
                :default-start-date="periodStartDate"
                :default-end-date="periodEndDate"
                @period-selected="onPeriodSelected"
              />
            </b-col>
          </b-row>

          <b-row no-gutters>
            <b-col class="col-lg-9">
              <div v-html="$t(translationPath + 'description')"></div>
            </b-col>
          </b-row>

          <b-row no-gutters>
            <b-col>
              <b-form-group
                label-class="align-self-center pr-md-0 pr-lg-4 text-black"
                label-for="account"
                label-cols-lg="2"
                label-cols-md="6"
                required
                :invalid-feedback="invalidAccountFeedback"
                :state="!$v.account.$dirty && !$v.account.$error"
                :label="$t(translationPath + 'account')"
              >
                <custom-select
                  id="account"
                  class="mb-2"
                  :extra-classes="accountFieldClasses"
                  :options="accountOptions"
                  grouping-values="accounts"
                  grouping-label="label"
                  :label="'label'"
                  :loading="!isLoaded"
                  v-model="account"
                  :placeholder="$t('common.select_account')"
                  :search-placeholder="$t('common.type_to_filter')"
                  :noResultText="$t(translationPath + 'no_more_items')"
                  @input="onAccountInput"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters class="pt-2" v-if="account !== null">
            <!-- START: Transaction history -->
            <b-col cols="9" style="border-right:1px solid #dda3a2" class="pr-4">
              <b-row no-gutters>
                <b-col cols="12" md="6" lg="8">
                  <h3>{{ $t(translationPath + 'title_transaction_history', {account_title: this.account ? this.account.title : '', start_date: this.periodStartDate, end_date: this.periodEndDate}) }}</h3>
                </b-col>
                <b-col cols="12" md="6" lg="4" class="mb-2">
                  <balance-fix-toggle-columns :options="tableColumnShowOptions" @column-toggle="onColumnToggle" />
                </b-col>
              </b-row>
              <b-table class="spirecta-simple-table balance-fix-transaction-table mb-0" show-empty hover responsive striped stacked="sm"
                       ref="TransactionsTable"
                       :items="aTransactions"
                       :fields="aTransactionTableColumns"
                       :busy="!areTransactionsLoaded"
                       :tbody-tr-class="transactionsTableTrClass"
                       :filter="sTableFilter"
                       :filter-included-fields="['title']"
                       :per-page="iPaginationPerPage"
                       :current-page="iPaginationCurrentPage"
                       :emptyFilteredText ="$t('common.no_filter_result')"
                       :emptyText="$t(translationPath + 'misc.no_data')"
                       @filtered="onTransactionsFiltered"
              >
                <!-- FILTER -->
                <template slot="top-row">
                  <td :colspan="this.aTransactionTableColumns.length">
                    <div class="d-flex align-items-center">
                      <i class="fa fa-search text-secondary"></i>
                      <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                    </div>
                  </td>
                </template>

                <template v-slot:head(verified)>
                  <b-form-checkbox
                    v-model="isAllVerified"
                    value="1"
                    unchecked-value="0"
                    :indeterminate="isVerifiedAllInterminate"
                    @change="onVerifyAllChange"
                  />
                </template>

                <!-- date slot-->
                <template v-slot:cell(date)="row">
                  <span class="text-nowrap">{{ row.item.date_fix ? row.item.date_fix : row.value }}</span>
                </template>

                <!-- title slot-->
                <template v-slot:cell(title)="row">
                  <template v-if="row.item.row_type==='transaction'">
                    <b-link :to="'/transactions/view/transaction/'+row.item.id">{{row.value}}</b-link>
                  </template>
                </template>

                <!-- amount slot-->
                <template v-slot:cell(amount)="row">
                  <template v-if="row.item.row_type==='transaction'">
                    <span :class="'transaction_' + row.item.transaction_subtype" class="text-nowrap">{{row.value}}</span>
                   </template>
                </template>

                <template v-slot:cell(description)="row">
                  <i18n v-if="row.item.transaction_subtype" :path="'common.transaction_subtypes.'+row.item.transaction_subtype" tag="span" class="text-dark transaction-description">
                    <template v-slot:cr_account v-if="Object.prototype.hasOwnProperty.call(row.item, 'cr_entries') && ( 0 in row.item.cr_entries)"><b-link :href="'/reports/performance/accounts/'+row.item.cr_entries[0].account_id+'/view'" class="text-dark">{{ row.item.cr_entries[0].account_title }}</b-link></template>
                    <template v-slot:dr_account v-if="Object.prototype.hasOwnProperty.call(row.item, 'dr_entries') && ( 0 in row.item.dr_entries)"><b-link :href="'/reports/performance/accounts/'+row.item.dr_entries[0].account_id+'/view'" class="text-dark">{{ row.item.dr_entries[0].account_title }}</b-link></template>
                  </i18n>
                </template>

                <template v-slot:cell(verified)="row">
                  <b-form-checkbox
                    v-if="row.item.id"
                    v-model="row.item.verified"
                    value="1"
                    unchecked-value="0"
                    @change="onVerifyedChange"
                  />
                </template>

                <template v-slot:cell(actions)="row">
                  <b-button
                    v-if="row.item.row_subtype === 'transaction' && row.item.id || row.item.row_subtype === 'preview_transaction'"
                    @click="onTransactionDeleteClick(row.item)"
                    :title="$t('common.delete')"
                    class="plain-btn action-button"
                  >
                    <i class="flaticon solid trash-3 text-danger"></i>
                  </b-button>
                </template>

              </b-table>
              <!-- PAGINATION -->
              <div class="pagination-wrapper" v-if="this.isLoaded">
                <div class="d-flex  flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
                    <div v-if="iPaginationTotalRows > 0" class="d-flex align-items-center justify-content-center show-per-page-wrapper">
                      <span class="text-gray">{{ $t('common.pagination.showing')}} {{(iPaginationCurrentPage - 1) * iPaginationPerPage + 1}} {{ $t('common.pagination.to')}} {{ paginationToNumber() }} {{ $t('common.pagination.of')}} {{iPaginationTotalRows}}</span>
                    </div>
                    <div v-if="iPaginationTotalRows > 0" class="space-divider"></div>
                    <div class="d-flex align-items-center justify-content-center show-per-page-wrapper">
                      <span class="text-gray">{{ $t('common.pagination.show_per_page')}}: </span> <b-form-select :options="aPaginationShowPerPageOptions" v-model="iPaginationPerPage" class="selectPerPage" />
                    </div>
                    <div class="space-divider"></div>
                    <div v-if="iPaginationTotalRows > 0" class="d-flex align-items-center justify-content-center show-per-page-wrapper">
                      <span><b-link :to="'/transactions/view/transactions?start_date=' + this.periodStartDate +' &end_date=' + this.periodEndDate +'&source_account_id=' + (this.account ? this.account.id : '')" class="text-gray">{{$t(translationPath + 'open_in_transaction_view')}}</b-link></span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center pagination-pages-wrapper">
                    <b-pagination v-if="iPaginationTotalRows > 0" :total-rows="iPaginationTotalRows" :per-page="( iPaginationPerPage)" :prev-text="$t('common.pagination.prev')" :next-text="$t('common.pagination.next')" v-model="iPaginationCurrentPage" class="my-0"/>
                  </div>
                </div>
              </div>
              <!-- END PAGINATION -->

              <b-row no-gutters>
                <b-col class="text-right mt-2">
                  <b-button
                    v-if="tableColumnShowOptions.verified"
                    variant="primary"
                    :disabled="!isLoaded || isInProcess"
                    @click="saveCheckedTrasnactionsAsVerified"
                  >
                    {{ $t(translationPath + 'btn_save_verified') }}
                  </b-button>
                </b-col>
              </b-row>

            </b-col>
            <!-- END: Transaction history -->

            <!-- START: Correction date -->
            <b-col cols="3" class="pl-3">
              <b-tabs card class="white-tabs">
                <b-tab :title="$t(translationPath + 'sidebar.correction.tab_title')" class="mx-0 px-0">
                  <balance-fix-sidebar-correction
                    :account-id="account ? account.id : 0"
                    :account-name="account ? account.title : ''"
                    :selected-lang="selectedLang"
                    :start-week-by-monday="startWeekByMonday"
                    :account-options="accountAllOptions"
                    :currency="currentCOA.currency_iso ? currentCOA.currency_iso :'SEK'"
                    :locale="currentCOA.locale ? currentCOA.locale : 'sv-SE'"
                    :is-loaded="isLoaded"
                    :disable-buttons="isInProcess"
                    :is-preview-loaded="isPreviewLoaded"
                    :first-transaction-date="firstTransactionDate"
                    @preview="correctionPreview"
                    @clear-preview="clearCorrectionPreview"
                    @fix-balance="correctValue"
                  />
                </b-tab>
                <b-tab :title="$t(translationPath + 'sidebar.custom.tab_title')" class="mx-0 px-0">
                  <balance-fix-sidebar-custom
                    :account-id="account ? account.id : 0"
                    :account-title="account ? account.title : ''"
                    :selected-lang="selectedLang"
                    :start-week-by-monday="startWeekByMonday"
                    :account-options="accountAllOptions"
                    :currency="currentCOA.currency_iso ? currentCOA.currency_iso :'SEK'"
                    :locale="currentCOA.locale ? currentCOA.locale : 'sv-SE'"
                    :is-loaded="isLoaded"
                    :disable-buttons="isInProcess"
                    :is-preview-loaded="isPreviewLoaded"
                    @preview="correctionPreview"
                    @clear-preview="clearCorrectionPreview"
                    @create-transaction="correctValue"
                    @select-main-account="(accountId) => selectAccount(parseInt(accountId))"
                  />
                </b-tab>
              </b-tabs>
            </b-col>
            <!-- END: Correction date -->
          </b-row>
        </div>
      </b-container>
    </b-container>

    <delete-modal
      ref="DeleteTransactionModal"
      @on-delete-cancel="onDeleteCancel"
      @on-delete-confirm="onDeleteConfirm"
      :heading="$t(translationPath + 'modal_delete.title')"
      :message="$t(translationPath + 'modal_delete.text', { title: this.transactionToDelete ? this.transactionToDelete.title : '' })"
    />

    <delete-modal
      ref="DeletePreviewTransactionModal"
      @on-delete-cancel="onDeleteCancel"
      @on-delete-confirm="clearCorrectionPreview"
      :heading="$t(translationPath + 'modal_delete_preview.title')"
      :message="$t(translationPath + 'modal_delete_preview.text')"
    />

    <success-modal
      :title="$t(translationPath + 'success_modal.title').toString()"
      :message="$t(translationPath + 'success_modal.message', { title: title }).toString()"
      :button="$t(translationPath + 'success_modal.ok_stay_on_page').toString()"
      :redirect="'/redirect?to=balanceFix&account_id=' + (account ? account.id : '')"
      :orLink="'/reports/performance/accounts/' + (account ? account.id : '') + '/view'"
      :orLinkText="$t(translationPath + 'success_modal.or_link_text').toString()"
      @hide="onSuccessModalHide"
    ></success-modal>

    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import titleMixins from '@/mixins/title'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import CustomSelect from '@/components/common/CustomSelect'
import PeriodSelector from '@/components/common/PeriodSelector'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import { required /* , minLength, maxLength */ } from 'vuelidate/lib/validators'
import SuccessModal from '@/components/modals/SuccessModal'
import formatAmount from '@/assets/filters/formatAmount'
import BalanceFixToggleColumns from './BalanceFixToggleColumns'
import DeleteModal from '@/components/modals/DeleteModal'
import BalanceFixSidebarCorrection from './BalanceFixSidebarCorrection'
import BalanceFixSidebarCustom from './BalanceFixSidebarCustom'
import _ from 'lodash'

export default {
  name: 'BalanceFix',
  mixins: [titleMixins],
  components: {
    CustomSelect,
    SuccessModal,
    PeriodSelector,
    BalanceFixToggleColumns,
    DeleteModal,
    BalanceFixSidebarCorrection,
    BalanceFixSidebarCustom
  },
  filters: { displayAccountTitle },
  data () {
    return {
      translationPath: 'transactions.balance-fix.',
      isLoaded: false,
      areTransactionsLoaded: false,
      isInProcess: false,
      sTableFilter: '',
      account: null,
      apiData: {},
      accountOptions: null,
      accountAllOptions: null,
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      periodStartDate: moment().startOf('year').format('YYYY-MM-DD'),
      periodEndDate: moment().format('YYYY-MM-DD'),
      aTransactions: [],
      aTransactionsFiltered: [],
      iPaginationPerPage: 10,
      iPaginationCurrentPage: 1,
      iPaginationTotalRows: 0,
      aPaginationShowPerPageOptions: [5, 10, 25, 50, 100, 150, 250, 500],
      tableColumnShowOptions: {
        description: false,
        verified: false,
        actions: false
      },
      isPreviewLoaded: false,
      isAllVerified: '0',
      transactionToDelete: null,
      previewBalanceSupplement: 0,
      title: '',
      firstTransactionDate: moment().format('YYYY-MM-DD')
    }
  },
  validations: {
    account: { required },
    form: ['account']
  },
  created () {
    if (this.$route.query.date) {
      this.amountDate = new Date(this.$route.query.date)
      this.correctionDate = new Date(this.$route.query.date)
    }
    if (this.$route.query.start_date) {
      this.periodStartDate = this.$route.query.start_date
    }
    if (this.$route.query.end_date) {
      this.periodEndDate = this.$route.query.end_date
    }

    this.fetchAccountsForMultiselect()
      .then(() => {
        if (this.$route.params.account_id) {
          this.selectAccount(Number(this.$route.params.account_id))
        }
      })
      .finally(() => {
        this.isLoaded = true
      })
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    aTransactionTableColumns () {
      const columns = [
        { key: 'date', label: this.$t('common.date'), sortable: false, class: 'text-left td-date' },
        { key: 'title', label: this.$t('common.title'), sortable: false, class: 'text-left td-title' },
        {
          key: 'amount',
          label: this.$t('common.amount'),
          sortable: false,
          class: 'text-right td-amount',
          formatter: this.formatAmt
        }
      ]

      if (this.tableColumnShowOptions.description) {
        columns.push({
          key: 'description',
          label: this.$t('common.description'),
          sortable: false,
          class: 'text-left td-description'
        })
      }

      columns.push({
        key: 'balance',
        label: this.$t(this.translationPath + 'balance'),
        sortable: false,
        class: 'text-right td-balance',
        formatter: this.formatAmt
      })

      if (this.tableColumnShowOptions.verified) {
        columns.push({
          key: 'verified',
          label: this.$t('common.verified'),
          sortable: false,
          class: 'text-right td-verified'
        })
      }

      if (this.tableColumnShowOptions.actions) {
        columns.push({
          key: 'actions',
          label: '',
          sortable: false,
          class: 'text-right td-actions'
        })
      }

      return columns
    },
    breadcrumbItems () {
      const aBreadcrumb = [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: '', to: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    tableFields () {
      return [
        { key: 'title', label: this.$t(this.translationPath + 'table.th.title'), sortable: true },
        { key: 'description', label: this.$t(this.translationPath + 'table.th.description'), sortable: true },
        { key: 'is_active', label: this.$t(this.translationPath + 'table.th.is_active'), sortable: true },
        { key: 'actions', label: '', sortable: false }
      ]
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    accountFieldClasses () {
      return {
        'is-valid': this.$v.account.$dirty && !this.$v.account.$error,
        'is-invalid': this.$v.account.$dirty && this.$v.account.$error
      }
    },
    isVerifiedAllInterminate () {
      if (!this.aTransactions.length) {
        return false
      }
      const allLen = this.aTransactions.filter(t => t.id).length
      const verifiedLen = this.aTransactions.filter(t => t.id && t.verified === '1').length

      if (verifiedLen && allLen !== verifiedLen) {
        return true
      }
      return false
    },
    invalidAccountFeedback () {
      if (this.$v.account.required === false) { return this.$t(this.translationPath + 'errors.account.required') }
      return ''
    }
  },
  methods: {
    async fetchAccountsForMultiselect () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=asset,liability,income,expense&include_networth_account=1&log=BalanceFix`)
          .then(response => {
            const optionArray = []
            const accountGroups = response.data.data
            if (this.$route.name === 'transactions-create') {
              accountGroups.map(group => {
                group.accounts = group.accounts.filter(account => account.status === 'active')
              })
            }
            Object.keys(accountGroups).forEach(function (key) {
              const allAccounts = accountGroups[key].accounts.map(accountItems => {
                return Object.assign(accountItems, {
                  label: displayAccountTitle(accountItems.title, accountItems.code, this.currentCOA.prefix_account_title_with_code)
                })
              })
              optionArray.push({ title: displayAccountTitle(accountGroups[key].title, accountGroups[key].code, this.currentCOA.prefix_account_title_with_code), type: accountGroups[key].type, accounts: allAccounts })
            }.bind(this))

            optionArray.map(accountOptions => {
              const type = this.$te('common.transaction_types.' + accountOptions.type.toLowerCase()) ? this.$t('common.transaction_types.' + accountOptions.type.toLowerCase()) : accountOptions.type
              const label = type + ' / ' + accountOptions.title
              return Object.assign(accountOptions, { label })
            })

            this.accountAllOptions = optionArray
            this.accountOptions = optionArray.filter(g => {
              if (g.accounts.length === 1 && g.accounts[0].id === this.currentCOA.networth_account_id) {
                return false
              }
              return ['asset', 'liability'].indexOf(g.type) !== -1
            })
            resolve(this.accountOptions)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    async fetchAccountData () {
      return new Promise((resolve, reject) => {
        if (!this.account || !this.amountDate || this.isInProcess) {
          resolve(null)
        }

        this.isInProcess = true
        const sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/balance/accounts/${this.account.id}/view?include_transactions=1&start_date=${this.periodStartDate}&end_date=${this.periodEndDate}`

        this.requestFetchAccountData(sApiEndpoint)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
          .finally(() => {
            this.isInProcess = false
          })
      })
    },
    async requestFetchAccountData (sApiEndpoint) {
      return new Promise((resolve, reject) => {
        axios.get(sApiEndpoint)
          .then(response => {
            this.apiData = response.data.data
            this.areTransactionsLoaded = true
            this.firstTransactionDate = this.apiData.meta.account_first_transaction_date

            this.aTransactions = []
            this.aTransactions.push({
              date: '',
              title: this.$t(this.translationPath + 'outgoing_balance'),
              amount: 0,
              balance: this.apiData.distribution.balance.outgoing,
              real_balance: this.apiData.distribution.balance.outgoing,
              row_type: 'transaction',
              row_subtype: 'transaction'
            })
            this.aTransactions = this.aTransactions.concat(this.apiData.transactions.map(item => {
              item.row_type = 'transaction'
              item.row_subtype = item.id ? 'transaction' : 'preview_transaction'
              item.real_balance = item.balance
              return item
            }))

            this.aTransactions.push({
              date: '',
              title: this.$t(this.translationPath + 'incoming_balance'),
              amount: 0,
              balance: this.apiData.distribution.balance.incoming,
              real_balance: this.apiData.distribution.balance.incoming,
              row_type: 'transaction',
              row_subtype: 'open_close_transaction'
            })

            this.sTableFilter = ''
            this.aTransactionsFiltered = this.aTransactions

            this.iPaginationTotalRows = this.aTransactions.length + 2
            this.isPreviewLoaded = false
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    formatAmt (fAmount) {
      if (this.currentCOA.locale && fAmount !== '') {
        return formatAmount(fAmount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 2)
      }
      return fAmount
    },
    selectAccount (accountId) {
      if (this.account) {
        this.$router.push({ params: { account_id: accountId } })
      }

      for (const g in this.accountOptions) {
        for (const a in this.accountOptions[g].accounts) {
          if (this.accountOptions[g].accounts[a].id === accountId) {
            this.account = this.accountOptions[g].accounts[a]
            this.fetchAccountData()
            break
          }
        }
      }
    },
    onAccountInput (option) {
      if (option.id !== parseInt(this.$route.params.account_id)) {
        this.$router.push({ params: { account_id: option.id } })
      }
      this.fetchAccountData()
    },
    onSuccessModalHide () {
      this.$router.push('/redirect?to=balanceFix&account_id' + this.account.id)
    },
    // Calculate X for label "showing 1 to X"
    paginationToNumber () {
      let iToNumber = this.iPaginationCurrentPage * this.iPaginationPerPage
      if (iToNumber > this.iPaginationTotalRows) {
        iToNumber = this.iPaginationTotalRows
      }
      return iToNumber
    },
    correctValue (data) {
      if (!this.isLoaded || this.isInProcess) {
        return false
      }

      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      this.isInProcess = true
      this.title = data.title

      let postData = {}
      if (data.type === 'correction_transaction') {
        postData = this.makeCorrectValueCorrectionPostData(data)
      } else if (data.type === 'custom_transaction') {
        postData = this.makeCorrectValueCustomPostData(data)
      } else {
        console.error('Unknown correction type.')
        return false
      }

      axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, postData)
        .then(response => {
          this.$bvModal.show('successModal')
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.fail').toString(), {
            title: this.$t(this.translationPath + 'toast.title').toString(),
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000
          })
        })
        .finally(() => {
          this.isInProcess = false
        })
    },
    makeCorrectValueCorrectionPostData (data) {
      const postData = {
        transactions: [{
          title: data.title,
          date: moment(data.correctionDate).format('YYYY-MM-DD'),
          type: 'transfer',
          entries: [
            {
              account_id: this.account.id,
              amount: Math.abs(data.correctionAmount),
              dctype: null
            },
            {
              account_id: this.currentCOA.networth_account_id,
              amount: Math.abs(data.correctionAmount),
              dctype: null
            }
          ]
        }]
      }

      switch (this.account.type) {
        case 'asset':
          if (data.actualAmount > data.dateAmount) {
            postData.transactions[0].entries[0].dctype = 'dr'
            postData.transactions[0].entries[1].dctype = 'cr'
          } else {
            postData.transactions[0].entries[0].dctype = 'cr'
            postData.transactions[0].entries[1].dctype = 'dr'
          }
          break
        case 'liability':
          if (data.actualAmount > data.dateAmount) {
            postData.transactions[0].entries[0].dctype = 'cr'
            postData.transactions[0].entries[1].dctype = 'dr'
          } else {
            postData.transactions[0].entries[0].dctype = 'dr'
            postData.transactions[0].entries[1].dctype = 'cr'
          }
      }

      return postData
    },
    makeCorrectValueCustomPostData (data) {
      const postData = {
        transactions: [{
          title: data.title,
          date: moment(data.date).format('YYYY-MM-DD'),
          type: 'transfer',
          entries: [
            {
              account_id: data.debitAccount.id,
              amount: Math.abs(data.amount),
              dctype: 'dr'
            },
            {
              account_id: data.creditAccount.id,
              amount: Math.abs(data.amount),
              dctype: 'cr'
            }
          ]
        }]
      }

      return postData
    },
    correctionPreview (data) {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      let previewTransaction = null
      if (data.type === 'correction_transaction') {
        this.previewBalanceSupplement = data.correctionAmount
        previewTransaction = this.makePreviewCorrectionTransaction(data)
      } else if (data.type === 'custom_transaction') {
        this.previewBalanceSupplement = this.calculateBalanceSupplementForPreview(data)
        previewTransaction = this.makePreviewCustomTransaction(data)
      } else {
        console.error('Unknown preview type.')
        return false
      }

      this.insertPreviewTransaction(previewTransaction)
      this.recalculateBalance()

      this.aTransactionsFiltered = this.aTransactions
      this.sTableFilter = ''
      this.isPreviewLoaded = true

      this.$bvToast.toast(this.$t(this.translationPath + 'toast.load_accounts.success_with_preview').toString(), {
        title: this.$t(this.translationPath + 'toast.title').toString(),
        variant: 'success',
        solid: true,
        autoHideDelay: 5000
      })
    },
    makePreviewCorrectionTransaction (data) {
      const previewTransaction = {
        row_type: 'transaction',
        row_subtype: 'preview_transaction',
        source_sidebar: 'correction',
        transaction_subtype: null,
        title: this.$t(this.translationPath + 'preview_title_prefix') + ' ' + data.title,
        amount: Math.abs(data.correctionAmount),
        date: moment(data.correctionDate).format('YYYY-MM-DD'),
        balance: 0,
        real_balance: 0,
        cr_entries: [],
        dr_entries: []
      }

      const entry1 = {
        account_code: this.account.code,
        account_id: this.account.id,
        account_title: this.account.title,
        account_type: this.account.type,
        entry_type: null,
        title_with_code: this.account.title_with_code
      }
      const entry2 = {
        account_code: null,
        account_id: this.currentCOA.networth_account_id,
        account_title: null,
        account_type: 'liability',
        entry_type: null,
        title_with_code: null
      }

      switch (true) {
        case this.account.type === 'asset' && data.correctionAmount >= 0:
          previewTransaction.transaction_subtype = 'transfer_networth_to_asset'
          previewTransaction.cr_entries.push(entry2)
          previewTransaction.dr_entries.push(entry1)
          break
        case this.account.type === 'asset' && data.correctionAmount < 0:
          previewTransaction.transaction_subtype = 'asset_value_decrease_networth_account'
          previewTransaction.cr_entries.push(entry1)
          previewTransaction.dr_entries.push(entry2)
          break
        case this.account.type === 'liability' && data.correctionAmount >= 0:
          previewTransaction.transaction_subtype = 'transfer_networth_to_liability_starting_value'
          previewTransaction.cr_entries.push(entry1)
          previewTransaction.dr_entries.push(entry2)
          break
        case this.account.type === 'liability' && data.correctionAmount < 0:
          previewTransaction.transaction_subtype = 'liability_amortization_networth_account'
          previewTransaction.cr_entries.push(entry2)
          previewTransaction.dr_entries.push(entry1)
      }

      return previewTransaction
    },
    makePreviewCustomTransaction (data) {
      const previewTransaction = {
        row_type: 'transaction',
        row_subtype: 'preview_transaction',
        source_sidebar: 'custom',
        transaction_subtype: this.definePreviewTransactionSubtype(data),
        title: this.$t(this.translationPath + 'preview_title_prefix') + data.title,
        amount: Math.abs(data.amount),
        date: moment(data.date).format('YYYY-MM-DD'),
        balance: 0,
        real_balance: 0,
        cr_entries: [{
          account_code: null,
          account_id: data.creditAccount.id,
          account_title: data.creditAccount.title,
          account_type: data.creditAccount.type,
          entry_type: null,
          title_with_code: data.creditAccount.title_with_code
        }],
        dr_entries: [{
          account_code: null,
          account_id: data.debitAccount.id,
          account_title: data.debitAccount.title,
          account_type: data.debitAccount.type,
          entry_type: null,
          title_with_code: data.debitAccount.title_with_code
        }]
      }

      return previewTransaction
    },
    insertPreviewTransaction (previewTransaction) {
      this.aTransactions = this.aTransactions.filter(t => t.row_subtype !== 'preview_transaction')

      const openingBalanceTransaction = this.aTransactions.shift()
      const closingBalanceTransaction = this.aTransactions.pop()

      this.aTransactions.unshift(previewTransaction)
      this.aTransactions.sort((a, b) => {
        if (a.date < b.date) {
          return 1
        } else if (a.date > b.date) {
          return -1
        } else if (a.date === b.date && !a.id) {
          return -1
        } else if (a.date === b.date && !b.id) {
          return 1
        } else if (a.date === b.date && a.id && b.id && a.id < b.id) {
          return 1
        } else {
          return -1
        }
      })

      this.aTransactions.unshift(openingBalanceTransaction)
      this.aTransactions.push(closingBalanceTransaction)
    },
    recalculateBalance () {
      let supplement = 0
      let prevBalance = 0
      this.aTransactions.slice(0).reverse().map(transaction => {
        transaction.balance = transaction.real_balance
        if (transaction.row_subtype === 'preview_transaction') {
          supplement = this.previewBalanceSupplement
          transaction.balance = prevBalance
        }
        prevBalance = transaction.balance
        transaction.balance += supplement
      })
    },
    clearCorrectionPreview () {
      this.aTransactions = this.aTransactions.filter(t => t.row_subtype !== 'preview_transaction')
      this.recalculateBalance()

      this.aTransactionsFiltered = this.aTransactions
      this.sTableFilter = ''
      this.isPreviewLoaded = false

      this.$bvToast.toast(this.$t(this.translationPath + 'toast.load_accounts.success_clear_preview').toString(), {
        title: this.$t(this.translationPath + 'toast.title').toString(),
        variant: 'success',
        solid: true,
        autoHideDelay: 5000
      })
    },
    calculateBalanceSupplementForPreview (data) {
      const supplement = Math.abs(data.amount)
      const currentAccountCrDrType = data.creditAccount.id === this.account.id ? 'Cr' : 'Dr'
      const currentAccountType = this.account.type.substring(0, 1).toUpperCase() + this.account.type.substring(1)
      const propName = 'current' + currentAccountType + currentAccountCrDrType
      const offsetAccountType = currentAccountCrDrType === 'Cr' ? data.debitAccount.type : data.creditAccount.type

      const map = {
        currentAssetCr: { income: '-', expense: '-', asset: '-', liability: '+' },
        currentAssetDr: { income: '+', expense: '+', asset: '+', liability: '+' },
        currentLiabilityCr: { income: '+', expense: '+', asset: '+', liability: '+' },
        currentLiabilityDr: { income: '-', expense: '-', asset: '-', liability: '-' }
      }

      if (map[propName][offsetAccountType] === '-') {
        return 0 - supplement
      }
      return supplement
    },
    definePreviewTransactionSubtype (data) {
      const map = {
        currentAssetCr: {
          income: 'asset_value_decrease',
          expense: 'expense_debit',
          asset: 'transfer_asset_to_asset',
          liability: 'liability_amortization'
        },
        currentAssetDr: {
          income: 'income',
          expense: 'transfer_refund',
          asset: 'transfer_asset_to_asset',
          liability: 'transfer_liability_to_asset'
        },
        currentLiabilityCr: {
          income: '',
          expense: 'expense_credit',
          asset: 'transfer_liability_to_asset',
          liability: 'transfer_liability_to_liability'
        },
        currentLiabilityDr: {
          income: 'income_to_liability',
          expense: 'transfer_refund',
          asset: 'liability_amortization',
          liability: 'transfer_liability_to_liability'
        }
      }

      const currentAccountCrDrType = data.creditAccount.id === this.account.id ? 'Cr' : 'Dr'
      const currentAccountType = this.account.type.substring(0, 1).toUpperCase() + this.account.type.substring(1)
      const propName = 'current' + currentAccountType + currentAccountCrDrType
      const offsetAccountType = currentAccountCrDrType === 'Cr' ? data.debitAccount.type : data.creditAccount.type

      return map[propName][offsetAccountType]
    },
    onPeriodSelected (periodData) {
      this.periodStartDate = periodData.sDateStart
      this.periodEndDate = periodData.sDateEnd

      const query = _.cloneDeep(this.$route.query)
      query.start_date = this.periodStartDate
      query.end_date = this.periodEndDate

      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.push({ query: query })
      }

      this.fetchAccountData()
    },
    onColumnToggle (column) {
      this.tableColumnShowOptions[column] = !this.tableColumnShowOptions[column]
    },
    saveCheckedTrasnactionsAsVerified () {
      if (this.isInProcess) {
        return false
      }

      this.isInProcess = true
      const putData = { transactions: [] }

      this.aTransactionsFiltered.filter(t => t.id).map(transaction => {
        putData.transactions.push({
          id: transaction.id,
          verified: transaction.verified
        })
      })

      if (!putData.transactions.length) {
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_as_verified.nothing_to_save').toString(), {
          title: this.$t(this.translationPath + 'toast.title').toString(),
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000
        })
        this.isInProcess = false
        return false
      }

      axios.put(`${process.env.VUE_APP_ROOT_API}/transactions/multiple-without-entries`, putData)
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_as_verified.success').toString(), {
            title: this.$t(this.translationPath + 'toast.title').toString(),
            variant: 'success',
            solid: true,
            autoHideDelay: 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_as_verified.save_fail').toString(), {
            title: this.$t(this.translationPath + 'toast.title').toString(),
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000
          })
        })
        .finally(() => {
          this.isInProcess = false
        })
    },
    onVerifyedChange (value) {
      process.nextTick(() => {
        const all = this.aTransactions.filter(t => t.id)
        const verified = this.aTransactions.filter(t => t.id && t.verified === '1')

        if (!verified.length) {
          this.isAllVerified = '0'
        } else if (verified.length === all.length) {
          this.isAllVerified = '1'
        }
      })
    },
    onVerifyAllChange (value) {
      this.aTransactions.map(transaction => {
        transaction.verified = value
      })
    },
    onTransactionsFiltered (filteredTransactions) {
      this.aTransactionsFiltered = filteredTransactions
    },
    transactionsTableTrClass (item, type) {
      if (type === 'row' && item.row_subtype === 'preview_transaction') {
        return 'selectPerPage'
      }
      return ''
    },
    onTransactionDeleteClick (item) {
      if (item.row_subtype === 'transaction') {
        this.transactionToDelete = item
        this.$refs.DeleteTransactionModal.show()
      } else if (item.row_subtype === 'preview_transaction') {
        this.$refs.DeletePreviewTransactionModal.show()
      }
    },
    onDeleteCancel () {
      this.transactionToDelete = null
    },
    onDeleteConfirm () {
      const data = { transaction_ids: [this.transactionToDelete.id] }
      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, { data: data })
        .then(response => {
          this.fetchAccountData()
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete_transaction.success', { title: this.transactionToDelete.title }).toString(), {
            title: this.$t(this.translationPath + 'toast.title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.transactionToDelete = null
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.fail').toString(), {
            title: this.$t(this.translationPath + 'toast.title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    'currentCOA.default_pagination_rows' () {
      this.iPaginationPerPage = this.currentCOA.default_pagination_rows
    }
  }
}
</script>

<style lang="scss">
.component-transactions-balance-fix {
  width: 100%;
  .page-wrapper{
    min-height: 100%;
    padding-left:35px;
    padding-right:35px;
  }
  .show-err {
    .invalid-feedback {
      display: block;
    }
  }
  .balance-fix-transaction-table tr.selectPerPage{
    background: #FFCA28!important;
    border:1px solid black!important;
    font-weight: bold;
  }

  .transaction-description {
    font-size: 90%;
    a {
      text-decoration: underline;
    }
  }
  table {
    .action-button {
      padding: 0;
      border: none;
      line-height: normal;
      i {
        margin: 0;
        font-size: 13px;
      }
      &:hover {
        background: transparent;
      }
      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }
      &:focus {
        background: transparent;
        box-shadow: none !important;
      }
    }
  }
}
</style>
